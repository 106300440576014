import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegisterFormFields, IRegisterSubmitField } from "@models/Register";
import FormValidation from "@/utils/validate";
import { fieldName } from "@/constants/validationFieldName";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { ILabInfo } from "@/models/Lab";
import { forEach } from "lodash";

interface IRegisterState {
  [key : string] :any,
  formField: IRegisterFormFields;
  validateRegister: any;
  verifyCode: IDataResponse;
  selectedLabo: null | ILabInfo;
  formFieldSubmit: IRegisterSubmitField;
  validateRegisterSubmit: any;
  fromFiledCustom: any;
  formFieldInfo: any;
  formMutiField: any;
  formMutiFieldInfo: any;
}
const initialState: IRegisterState = {
  formField: {
    name_mei: "",
    name_sei: "",
    email: "",
    password: "",
    passwordConfirm: "",
  },
  validateRegister: {},

  verifyCode: {
    success: false,
    message: "",
    data: null,
  },
  selectedLabo: null,

  formFieldSubmit: {
    lab_name: "",
    lab_corp_kbn: "",
    lab_name_f: "",
    lab_address: "",
    lab_building: "",
    pref_id: "",
    city_id: "",
    lab_id: "",
    lab_zip: "",
    lab_fax: "",
    lab_phone: "",
  },
  validateRegisterSubmit: {},
  formFieldInfo: {},
  fromFiledCustom: {},
  formMutiField: [],
  formMutiFieldInfo: {},
};
export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    resetFormField(state) {
      state.formField = {
        name_mei: "",
        name_sei: "",
        email: "",
        password: "",
        passwordConfirm: "",
      };

      state.validateRegister = {};
    },
    setFormField(
      state,
      action: PayloadAction<{ value: string; name: keyof IRegisterFormFields }>
    ) {
      const { value, name } = action.payload;
      state.formField[name] = value;
      state.validateRegister[name] = new FormValidation().required( value, fieldName[name] as string );

      if (name == "email") {
        state.validateRegister[name] = new FormValidation().email(value);
      }
      if (name == "password" || name == "passwordConfirm") {
        state.validateRegister[name] = new FormValidation().password(value);
        if (
          state.validateRegister?.password?.isValid &&
          state.validateRegister?.passwordConfirm?.isValid
        ) {
          state.validateRegister["notMatch"] =
            new FormValidation().passwordNotMatch(
              state.formField.password,
              state.formField.passwordConfirm
            );
        }
      }
    },

    setVerifyCode(state, action: PayloadAction<IDataResponse>) {
      state.verifyCode = action.payload;
    },

    setSelectedLabo(state, action: PayloadAction<ILabInfo | null>) {
      state.validateRegisterSubmit = {}
      state.selectedLabo = action.payload;
    },

    setSelectedLaboToSubmit(state, action: PayloadAction<ILabInfo>) {
      const {
        lab_name = "",
        lab_corp_kbn = "",
        lab_name_f = "",
        lab_address = "",
        lab_building = "",
        Pref: { id: pref_id = "" } = {},
        City: { id: city_id = "" } = {},
        id: lab_id = "",
        lab_zip = "",
        lab_fax = "",
        lab_phone = "",
      } = action.payload || {};

      state.formFieldSubmit = {
        lab_name,
        lab_corp_kbn,
        lab_name_f,
        lab_address,
        lab_building,
        pref_id: pref_id.toString(),
        city_id: city_id.toString(),
        lab_id: lab_id.toString(),
        lab_zip,
        lab_fax,
        lab_phone,
      };

      const keys = Object.keys(state.formFieldSubmit);
      keys.forEach((key) => {
        if (state.formFieldSubmit[key]) {
          state.validateRegisterSubmit[key] = new FormValidation().required(
            state.formFieldSubmit[key],
            fieldName[key] as string
          );
        } else {
          state.validateRegisterSubmit[key] = null;
        }
      });
    },

    setFormFieldSubmit(
      state,
      action: PayloadAction<{ value: string; name: keyof IRegisterSubmitField }>
    ) {
      const { value, name } = action.payload;
      state.formFieldSubmit[name] = value;

      let notRequired = ["lab_corp_kbn", "lab_building"];
      const validationForm: any = new FormValidation()

      if (!notRequired.includes(name as string)) {
        state.validateRegisterSubmit[name] = validationForm.required(
          value,
          fieldName[name] as string
        );

        if (name == "lab_phone") 
          state.validateRegisterSubmit[name] = validationForm.phone( value, fieldName[name] as string );
        
      }
    },
    setFormFieldLaboSubmit(state, action: PayloadAction<any>) {
      const { value, name, req, labelName } = action.payload;

      state.formFieldSubmit[name] = value;

      const _fieldName: string =
        labelName && labelName[name] ? labelName[name] : fieldName[name];

      let required = req.length ? req : ["lab_corp_kbn", "lab_building"];
      let validation: any = new FormValidation();

      if (required.includes(name as string))
        validation.required(value, _fieldName as string);

      if (name == "lab_phone")
        validation.phone(value, _fieldName as string, true);

      if (name == "lab_zip")
        validation.regex(value, _fieldName as string, "^[0-9]{7}$");

      if (name == "lab_fax") validation.numeric(value, _fieldName as string);

      state.validateRegisterSubmit[name] = validation?.respone;
    },

    //formMutiField

    setFormMutiField(
      state,
      action: PayloadAction<{ field: any; type: string }>
    ) {
      const { field, type }: any = action.payload;

      switch (type) {
        case "contrust":
          state.formMutiFieldInfo = { ...field };
          state.formMutiField = [];
          break;
        case "update":
          state.formMutiField = field;
          break;
        default:
          state.formMutiField = [...state.formMutiField, { ...field }];
          break;
      }
    },

    setFormMutiFieldSubmit(
      state,
      action: PayloadAction<{ value: any; name: string; index: string }>
    ) {
      const { value, name, index }: any = action.payload;

      state.formMutiField[index][name]["value"] =
        (typeof value as string) == "object" ? value : String(value);

      if (state.formMutiFieldInfo[name]?.required) {
        let _fieldName =
          state.formMutiFieldInfo[name]?.filedName || fieldName[name] || "";

        if ((typeof value as string) == "object")
          state.formMutiField[index][name]["validation"] =
            new FormValidation().checkbox(value, _fieldName as string);

        switch (state.formMutiFieldInfo[name]?.type) {
          case "phone":
            state.formMutiField[index][name]["validation"] =
              new FormValidation().phone(value, _fieldName as string);
            break;
          default:
            state.formMutiField[index][name]["validation"] =
              new FormValidation().required(value, _fieldName as string);
            break;
        }
      }
    },

    setFromFiledCustom(state, action: PayloadAction<any>) {
      const field: any = {};

      Object.keys(action.payload).forEach( (key: any) => (field[key] = ( typeof action.payload[key].val == "boolean" || action.payload[key].val ) ? action.payload[key].val : "") );
      
      state.validateRegisterSubmit = {};
      state.formFieldInfo = { ...action.payload };
      state.fromFiledCustom = field;
    },

    setFromFiledDyamic(state, action: PayloadAction<any>) {
      
      const { data, filed } = action.payload

      const fields: any = {};

      Object.keys(data)
            .forEach( (key: any) => (fields[key] = ( typeof data[key].val == "boolean" || data[key].val ) ? data[key].val : "") );
      
      state.validateRegisterSubmit = {};
      state.formFieldInfo = { ...data };
      state[filed] = fields;
    },

    setFromFiledCustomSubmit(state, action: PayloadAction<any>) {
      const { value, name } = action.payload;
      
      if( ! state.formFieldInfo[name] && ( name.search('-') > 0) && (typeof value as string == 'object') )
      {
         name.split("-").forEach( (row:any, ind:any) => state.fromFiledCustom[row] = value[row] )
          return state
      }
      
      state.fromFiledCustom[name] = value;

      if (state.formFieldInfo[name]?.required) {
        let _fieldName =
          state.formFieldInfo[name]?.filedName || fieldName[name] || "";

        const validateFiled: any = new FormValidation();
        validateFiled.variation = { required: true };

        if ((typeof value as string) == "object")
          validateFiled.checkbox(value, _fieldName as string);

        switch (state.formFieldInfo[name]?.type) {
          case "phone":
            validateFiled.phone(value, _fieldName as string);
            break;
          case "password":
            validateFiled.password(value );
            break;
          case "passwordC":
            validateFiled.passwordc( value, state.fromFiledCustom['password'] as string );
            break;
          case "regex":
            validateFiled.regex( value, _fieldName, state.formFieldInfo[name]?.regex );
            break;
          default:
            validateFiled.required(value, _fieldName as string);
            break;
        }
        
        state.validateRegisterSubmit[name] = validateFiled?.respone;
      }
    },

    setFromFiledInputDyamic(state, action: PayloadAction<any>) {
      const { value, name, filed } = action.payload;
      
      if( ! state.formFieldInfo[name] && ( name.search('-') > 0) && (typeof value as string == 'object') )
      {
         name.split("-").forEach( (row:any, ind:any) => state[filed as string][row] = value[row] )
          return state
      }

      state[filed as string][name] = value;

      if (state.formFieldInfo[name]?.required) {
        let _fieldName =
          state.formFieldInfo[name]?.filedName || fieldName[name] || "";

        const validateFiled: any = new FormValidation();
        validateFiled.variation = { required: true };

        if ((typeof value as string) == "object")
          validateFiled.checkbox(value, _fieldName as string);

        switch (state.formFieldInfo[name]?.type) {
          case "phone":
            validateFiled.phone(value, _fieldName as string);
            break;
          case "regex":
            validateFiled.regex(
              value,
              _fieldName,
              state.formFieldInfo[name]?.regex
            );
            break;
          default:
            validateFiled.required(value, _fieldName as string);
            break;
        }

        state.validateRegisterSubmit[name] = validateFiled?.respone;
      }
    },

  },
});

const authReducer = registerSlice.reducer;
const {
  setFormField,
  setVerifyCode,
  setSelectedLabo,
  setFormFieldSubmit,
  setSelectedLaboToSubmit,
  setFormFieldLaboSubmit,
  setFromFiledCustom,
  setFromFiledCustomSubmit,
  setFromFiledInputDyamic,
  setFromFiledDyamic,
  setFormMutiField,
  setFormMutiFieldSubmit,
  resetFormField,
} = registerSlice.actions;
export {
  authReducer,
  setFormField,
  setVerifyCode,
  setSelectedLabo,
  setFormFieldSubmit,
  setSelectedLaboToSubmit,
  setFormFieldLaboSubmit,
  setFromFiledCustom,
  setFromFiledCustomSubmit,
  setFromFiledInputDyamic,
  setFromFiledDyamic,
  setFormMutiField,
  setFormMutiFieldSubmit,
  resetFormField,
};
