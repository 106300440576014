import { useContext, useEffect } from "react";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import Head from "next/head";
import dynamic from "next/dynamic";

import { store } from "@/store";
import AssetsProvider, { AssetsContext } from "../context/theme";
import { HookContext, useHook } from "@/context/hook";
import ErrorBoundary from "@/errors/global";
import { imgAssets } from "@/constants/img";

// Dynamic imports
const Loading = dynamic(
  () => import("@/components/Loading").then((mod) => mod.Loading),
  {
    ssr: false,
  }
);
const Msg = dynamic(() => import("@/components/Msg"));
const AllModals = dynamic(
  () => import("@/components/modals").then((mod) => mod.AllModals),
  {
    ssr: false,
  }
);
const PauseTime = dynamic(
  () =>
    import("@/components/tasks/blocks/pauseTime").then((mod) => mod.PauseTime),
  {
    ssr: false,
  }
);

// Import all CSS
import "@/assets/scss/print.css";
import "@/assets/scss/printCustom.scss";
import "@/assets/scss/main.css";
import "@/assets/scss/custom.scss";
import "@/assets/scss/phuong.scss";
import "@/assets/scss/sidebar.scss";
import "@/assets/scss/weekPicker.scss";
import "@assets/scss/modal.css";
import "@/assets/scss/setting.scss";
import "@assets/scss/style.scss"
import '@fortawesome/fontawesome-free/css/all.min.css';

function App({ Component, pageProps }: AppProps) {
  const assetContext = useContext(AssetsContext) as any;
  const hookContext = useHook() as any;
  const merProps = { ...pageProps, ...assetContext };

  return (
    <ErrorBoundary {...merProps}>
      <Provider store={store}>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <link rel="icon" href={imgAssets.logo2.src} sizes="32x32" />
        </Head>
        <AssetsProvider>
          <HookContext.Provider value={hookContext}>
            <main className="main__body" id="main">
              <Msg />
              <Component {...merProps} />
              <Loading />
              <AllModals {...merProps} />
              <PauseTime />
            </main>
          </HookContext.Provider>
        </AssetsProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
