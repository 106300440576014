import { url } from "@/constants/urlRrefix";

export const getPermission = (permissions: Array<any>, pathname: string) => {
  let isEditPermission = false;
	let isViewPermission = false;

	let urlCheck = url.find( (val:any) =>  
    {
      let path = pathname.replace( "/[type]", "" )
                .replace( "/[id]", "" )
                .replace( "/branch", "" )
                .replace( "/implant", "" )
                .replace( "/variations", "" )
                .replace( "/regist", "" )
                .replace( "/create", "" )
                .replace( "/individual", "" )
                .replace( "/edit-category", "" )
      if( path.search("/statistic/department") < 0 )
        path = path.replace( "/department", "" )

      if( path.search("/setting/material") >= 0 )
      {
        path = path.replace( "/product", "" )
        path = path.replace( "/metal", "" )										
      }

      if( path.search("/setting/product") >= 0 )
      {
        path = path.replace( "/category", "" )									
      }

      return ( val?.url.search( path as string ) >= 0 )
    } 
  )?.filedName

	let screenPermission = permissions.find( (res:any) => urlCheck == res?.permission )
	switch(screenPermission?.option)
	{
		case "edit":
			isEditPermission = true;
			break;
		case "view": 
			isViewPermission = true;
	}
	
  return {
    isEditPermission,
    isViewPermission,
    screenPermission
  }
}