import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ICardForm } from "@/models/card/Card";
import FormValidation from "@/utils/validate";
import { fieldName } from "@/constants/validationFieldName";
interface ICardState {
  formField: ICardForm;
  validateField: {
    [key in keyof ICardForm]: { isValid?: boolean; message?: string; };
  };
}
const initialState: ICardState = {
  formField: {
    card_number: "",  
    card_month: "",
    card_year: "",
    card_cvc: "",
  },
  validateField: {} as ICardState["validateField"]
}
export const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    resetCard (state) 
    {
      state.formField = {
        card_number: "",  
        card_month: "",
        card_year: "",
        card_cvc: "",
      }
    },

    setFormField: (state, action: PayloadAction<{ value: string; name: keyof ICardForm }>) => {
      const { value, name } = action.payload;
      state.formField[name] = value;
      state.validateField[name] = new FormValidation().required(
        value,
        fieldName.card[name] as string
      );
      if(name === "card_number"){
        state.validateField[name] = new FormValidation().card(value);        
        if(value.length > 16){
          state.formField[name] = value.slice(0, 16);
        }
      }
      if(name === "card_cvc"){
        state.validateField[name] = new FormValidation().cardCvc(value);
        if(value.length > 3){
          state.formField[name] = value.slice(0, 4);
        }
      }
    }
  }
})
const cardReducer = cardSlice.reducer;
const {
  setFormField,
  resetCard
} = cardSlice.actions;
export {
  cardReducer,
  setFormField,
  resetCard,
};

